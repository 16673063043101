












































































































































































import { Validator } from '@/constant/Mixins';
import Component from 'vue-class-component';
import { Mixins, Prop, Watch } from 'vue-property-decorator';
import filters from '@/constant/Filters';
import { } from 'vue';
import { ProductAdditionalInterface, ProductDataModel, ProductModel, ProductSummaryModel } from '@/store/product/types';
import { Action, State } from 'vuex-class';
import { FormComparePropertyModel } from '@/store/product/properti/types';
import { ServerResponse } from '@/services/response.types';
import { PRODUCT_INSTALLMENT_MONTH_DIFF, PROPERTY_COVERAGE } from '@/constant/Enums';
import { CompareContentModel } from '@/store/product/mobil/types';

interface TableItem {
  id: string;
  name: string;
  provider_name: string;
  total: number;
  annual_price: string;
  sum_insured: string;
  type_insurance: string;
  rating: string;
  workshop: {
    local: number;
    others: number;
  };
  installment: { duration: number; unit: 'months'; nominal: number; }[];
  additional_fee: { label: string; nominal: number | string | ProductAdditionalInterface[]; }[];
  features: {
    fire: boolean;
    household_furniture: boolean;
    earthquake: boolean;
    commotion: boolean;
    flood: boolean;
  };
  brand: string;
  quotation_id: string;
  compare: string;
  _showDetails: boolean;
}

@Component({
  name: 'listProperti',
  components: {
    formProperty: () => import('@/components/formCompare/compareProperty.vue'),
    ProductDetail: () => import('@/components/ProductDetail.vue'),
    callMe: () => import('@/components/FormCallMe.vue'),
  },
  filters,
})
export default class listProperti extends Mixins(Validator) {
  @Action('CompareAction', { namespace: 'product/properti' }) CompareAction!: (params?: any) => Promise<ServerResponse>;
  @Action('GetSummary', { namespace: 'product/properti' }) GetSummary!: (quotation_id: string, params?: any) => Promise<ServerResponse>;
  @Action('CompareProductAction', { namespace: 'product/properti' }) CompareProductAction!: (quotation_ids: FormData) => Promise<ServerResponse>;

  @State('onLoad', { namespace: 'product/properti' }) onLoad!: boolean;
  @State('data', { namespace: 'product/properti' }) data!: ProductDataModel;
  @State('form', { namespace: 'product/properti' }) form!: FormComparePropertyModel;
  @State('summary', { namespace: 'product/properti' }) summary!: ProductSummaryModel;
  @State('listCompare', { namespace: 'product/properti' }) listCompare!: CompareContentModel[];

  @Watch('OnComparing') OnComparingChange(value: boolean) {
    if (value) document.body.classList.add('overflow-hidden');
    else document.body.classList.remove('overflow-hidden');
  }

  public compareLimit = 3;
  public comparisons: TableItem[] = [];
  public selected: TableItem = null;

  public get fieldsCompare(): any[] {
    let fields = [
      {
        class: 'side-header compare-product-item',
        key: 'label',
        stickyColumn: false,
      },
    ];
    this.comparisons.forEach((item, i) =>
      fields.push({
        class: 'compare-product-item',
        key: `produk_${i + 1}`,
        stickyColumn: false,
      })
    );
    return fields;
  }

  public get comparisonsAside(): { label: string; product1?: TableItem; product2?: TableItem; product3?: TableItem; }[] {
    let rows = [
      { label: 'Label', key_ref: ['brand&provider_name'] },
      { label: 'Produk', key_ref: ['name'] },
      { label: 'Harga Motor', key_ref: ['sum_insured'] },
      { label: 'Harga Premi', key_ref: ['annual_price'] },
      // { label: 'Rating', key_ref: ['rating'] },
      { label: 'Tipe Asuransi', key_ref: ['type_insurance'] },
      { label: 'Kebakaran', key_ref: ['features', 'fire'] },
      { label: 'Perabotan Rumah Tangga', key_ref: ['features', 'household_furniture'] },
      { label: 'Gempa Bumi', key_ref: ['features', 'earthquake'] },
      { label: 'Huru-Hara', key_ref: ['features', 'commotion'] },
      { label: 'Banjir', key_ref: ['features', 'flood'] },
      { label: 'Fitur', key_ref: ['feature'] },
      { label: 'Perlindungan Tambahan', key_ref: ['additional'] },
      // { label: 'Action', key_ref: [] },
    ].map((cols: any) => {
      this.comparisons.forEach((item, i) => (cols[`product_${i + 1}`] = { ...item, ...this.listCompare?.filter((v, i) => Number(v.product.product_id) === Number(item.id.split("_")[0]))[0]?.product || {} }));
      return cols;
    });

    return rows;
  }

  public showDetail = false;
  public showCallMe = false;
  public OnComparing = false;

  public selectedOrder = 0;

  public SortByOptions = [
    {
      text: 'The Lowest',
      value: 'lowest',
    },
    {
      text: 'The Highest',
      value: 'highest',
    },
    {
      text: 'Company A-Z',
      value: 'a-z',
    },
    {
      text: 'Company Z-A',
      value: 'z-a',
    },
  ];

  public fields = [
    {
      class: 'col-4',
      key: 'annual_price',
      label: 'Annual Price',
      sortable: false,
      _showDetails: true,
    },
    {
      class: 'col-4',
      key: 'additional_fee',
      label: 'Additional Fee',
      sortable: false,
    },
    {
      class: 'col-4',
      key: 'features',
      label: 'Insurance Features and Extra',
      sortable: false,
      headspan: [
        {
          label: 'Fire',
        },
        {
          label: 'Household Furniture',
        },
        {
          label: 'Earthquake',
        },
        {
          label: 'Commotion',
        },
        {
          label: 'Flood',
        },
      ],
    },
  ];

  public get items(): TableItem[] {
    // let type_insurance = PROPERTY_COVERAGE.filter((item) => item.value === this.form.coverage)[0].text;
    let type_insurance = PROPERTY_COVERAGE.filter((item) => item.value === 1)[0].text;
    return this.data.listdata.map((item) => {
      let additionalFee = 0;
      let admninistration = Number(item.admin_fee) + Number(item.handling_fee);
      let totalPremium: any = Number(item.basic_premi) + admninistration + additionalFee;

      return {
        id: `${item.product_id}_${totalPremium}`, // some list have same product_id. so combine with other value to make it different
        name: item.product_name,
        provider_name: item.provider_name,
        total: (item.workshop.local ?? 0) + (item.workshop.others ?? 0),
        annual_price: totalPremium,
        workshop: item.workshop,
        sum_insured: item.sum_insured,
        type_insurance: type_insurance,
        rating: item.rate_basic,
        installment: PRODUCT_INSTALLMENT_MONTH_DIFF.map((month) => {
          return {
            duration: month,
            unit: 'months',
            nominal: totalPremium / month,
          };
        }),
        additional_fee: [
          {
            label: 'Admninistration',
            nominal: Number(item.admin_fee) + Number(item.handling_fee),
          },
          {
            label: 'Additional',
            nominal: item.additional,
          },
        ],
        features: {
          fire: item.is_huru_hara === '1',
          household_furniture: false,
          earthquake: item.is_gempa === '1',
          commotion: false,
          flood: item.is_banjir === '1',
        },
        brand: item.logo,
        quotation_id: item.quotation_id,
        compare: item.compare,
        _showDetails: true,
      };
    });
  }

  public formCallMe = {
    name: '',
    email: '',
    phone: '',
  };

  public created(): void { }
  public mounted(): void {
    window.onresize = () => {
      if (this.comparisons.length > 0) this.OnCollapseShow(`compare-product-img`);
      if (this.OnComparing) this.OnCollapseShow(`comparer-product-img`);
      console.log('resize');
    };
  }

  /**
   * OnClickCompare
  */
  public OnClickCompare() {
    this.OnComparing = true;
    let form = new FormData;
    this.comparisons.forEach((v, i) => form.append(`param${i + 1}`, v.quotation_id));
    this.CompareProductAction(form).then((res) => {
      this.OnComparing = true;
      // this.comparisonsAside = this.comparisonsAside.map((i)=>{...i, })
    }).finally(() => this.OnComparing = true);
  }

  /**
   * OnCompare
  */
  public OnCompare(item: any) {
    this.comparisons.push(item);
  }

  /**
   * OnDeleteCompare
   */
  public OnDeleteCompare(item: any) {
    this.comparisons = this.comparisons.filter((v) => v.id !== item.id);
  }

  /**
   * GetRefferenceCompateAttribute
  */
  public GetRefferenceCompateAttribute(item: { label: string; key_ref: any[]; product_1?: TableItem; product_2?: TableItem; product_3?: TableItem; }, product_ref: string) {
    let value: any = item[product_ref];
    item.key_ref.forEach((key) => {
      if (key instanceof Array) value = value[key[0]];
      else if (typeof key === 'string') {
        if (key.indexOf('&') >= 0) {
          value = '';
          key.split('&').forEach((k, i) => {
            let refVal: string = item[product_ref];
            refVal = refVal[k] || '';

            let isHTML = refVal.indexOf('http') >= 0;
            if (isHTML) value += `<img ref="product-logo" src="${refVal}" class="img-thumbnail d-block mx-auto" style="height: 100px;" />`;
          });
        } else value = value[key];
      }
    });

    if (typeof value === 'boolean') return value ? `<i class="fa fa-check h4 text-success" aria-hidden="true"></i>` : `<i class="fa fa-times h4 text-danger" aria-hidden="true"></i>`;
    else if (Number(value) >= 1000) return this.formatCurrency(value);
    else if (value instanceof Array) return "<ul>" + value.map((item: any) => `<li>${item.detail_ind ?? item.additional_category_name_ind}</li>`).join("") + "</ul>";
    else return item.key_ref.length > 0 ? value : null;
  }

  /**
   * GetDetailSummary
   */
  public GetDetailSummary(item: ProductModel) {
    this.GetSummary(item.quotation_id).then((res) => {
      if (res.data) this.showDetail = true;
    });
  }

  /**
   * onClickCallMe
   */
  public onClickCallMe(item: TableItem) {
    this.selected = item;
    this.showCallMe = !this.showCallMe;
  }

  /**
   * OnCollapseShow
   */
  public OnCollapseShow(target: string) {
    let imgs = this.$refs[target] as HTMLImageElement[];
    if (imgs) {
      let height = imgs[imgs.length - 1].clientHeight;
      imgs.forEach((img: HTMLImageElement) => {
        img.style.height = `${height}px`;
      });
    }
  }
}
